import { fetchWrapperPortal } from "./fetch";

export const find = async () =>
  await fetchWrapperPortal({
    url: `moderation/find`,
    method: `GET`,
    addBearer: true
  });

export const setDeleteStatus = async (chatId, deleteFlag, userName) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PORTAL_API_ENDPOINT || 'https://portal-dev.api.hotmic.io'}/moderation/setDeleteStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'ApiKey': process.env.REACT_APP_APP_KEY
      },
      body: JSON.stringify({ chatId, deleteFlag, userName })
    });
    
    // Any successful status code (200-299) should be treated as success
    return response.ok;
  } catch (error) {
    console.error('Error in setDeleteStatus:', error);
    return false;
  }
};

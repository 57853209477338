import { needsRefresh, refreshAuth } from './auth';

// const portal_endpoint = "https://portal-api.hotmic.io"; // [PROD]
// const portal_endpoint = "https://portal-test.api.hotmic.io"; // [Cloud9] - TODO change this back to Prod before deploy
const portal_endpoint = "https://portal-dev.api.hotmic.io"; // [STAGING] - TODO change back to prod before deploy

let refreshPromise = null;

// Add list of public endpoints that don't require auth
const PUBLIC_ENDPOINTS = [
  'auth/login',
  'auth/oauth/login',
  'auth/password/reset',
  'admin/theme',  // Add the theme endpoint to public endpoints
  'users/me',     // Add the user refresh endpoint
];

export const fetchWrapperPortal = async ({ ...args }) => {
  try {
    // Check if endpoint requires auth
    const isPublicEndpoint = PUBLIC_ENDPOINTS.some(endpoint => args.url.startsWith(endpoint));

    // Only check for refresh if not a public endpoint and not a refresh request
    if (!isPublicEndpoint && args.url !== 'auth/refresh/' && needsRefresh()) {
      try {
        // Use a single refresh promise to prevent multiple simultaneous refresh attempts
        if (!refreshPromise) {
          refreshPromise = refreshAuth();
        }
        await refreshPromise;
        refreshPromise = null;
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        // Clear refresh promise on error
        refreshPromise = null;
        // Clear all auth state
        localStorage.clear();
        // Only redirect if we're not already heading to login
        if (!window.location.pathname.includes('/auth/login')) {
          window.location.href = '/auth/login';
        }
        throw refreshError;
      }
    }

    const fetchObj = {};
    fetchObj.headers = args.headers || {};
    fetchObj.method = args.method.toUpperCase();
    fetchObj.body = args.body ? args.body : null;
    fetchObj.headers["Content-Type"] = "application/json";
    fetchObj.headers["ApiKey"] = process.env.REACT_APP_APP_KEY;
    
    // Add auth header if token exists, even for public endpoints
    if (!fetchObj.headers.Authorization && args.addBearer) {
      const token = args.token || localStorage.getItem("access_token");
      if (token) {
        fetchObj.headers.Authorization = args.token ? args.token : `Bearer ${token}`;
      } else if (!isPublicEndpoint) {
        throw new Error('No authorization token available');
      }
    }

    if (args.signal) {
      fetchObj.signal = args.signal;
    }

    const result = await fetch(`${portal_endpoint}/${args.url}`, fetchObj);
    
    // Handle 401/403 responses only for non-public endpoints
    if (!isPublicEndpoint && (result.status === 401 || result.status === 403)) {
      // Clear auth state and redirect to login
      localStorage.clear();
      // Only redirect if we're not already heading to login
      if (!window.location.pathname.includes('/auth/login')) {
        window.location.href = '/auth/login';
      }
      throw new Error(`Authentication error: ${result.status}`);
    }
    
    return result && result.status === 204 ? result.status : await result.json();
  } catch (e) {
    console.error(`HTTP ERROR:`, e);
    throw e;
  }
};
